* {
  margin: 0;
  padding: 0;
}

p {
  font-family: 'Nunito Sans', sans-serif;
}
.nav_item .p__nav {
  font-family: 'Libre Bodoni', serif;
}

h1 {
  font-family: 'Montserrat', sans-serif;
}

.title__nav {
  padding-top: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 1680px) {
  p {
    font-size: 20px;
  }
  .p__projects {
    font-size: 17px;
  }

  nav {
    width: 100%;
    height: 60px;
    background-color: #edcd1f;
    z-index: 1;
  }
  .nav_comp {
    width: inherit;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    width: inherit;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    background-color: #edcd1ede;
    box-shadow: 0px 8px 16px 0px rgb(237 205 30);
    padding: 20px 15px;
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .drop_text {
    font-size: 15px;
  }

  .navbar {
    position: absolute;
  }
  .nav_components {
    display: flex;
    justify-content: flex-end;
  }
  .hr-shelf {
    margin: -30px auto 10px;
    padding: 0;
    height: 50px;
    border: none;
    border-bottom: 1px solid #1f1209;
    box-shadow: 0 20px 20px -20px #333;
    width: 95%;
  }

  .nav_item {
    padding-top: 2vh;
    width: 30%;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  .p__nav {
    padding-top: 1vmax;
    font-size: 30px;
  }

  .p__ind {
    padding-top: 20px;
  }

  .p__out {
    padding-top: 40px;
  }

  .p__dok {
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    position: absolute;
    top: 65%;
  }

  h1 {
    text-align: center;
  }

  .h1__main {
    word-spacing: 23px;
    line-height: 50px;
  }

  .topic {
    height: 70px;
    width: 425px;
    padding-top: 10px;
    text-align: center;
    background-color: rgba(255, 255, 255, 83%);
  }
  .title__product {
    position: absolute;
    margin-top: 7%;
    margin-left: 24%;
    font-size: 19px;
    z-index: 1;
    width: 50%;
    text-align: center;
    box-shadow: 0px 8px 16px 0px;
    background-color: white;
  }

  .wrapper__about {
    max-width: 100%;
    max-height: 600px;
    width: 100%;
    height: 600px;
  }

  .block__row__about {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .block__column__about {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .block__column {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
  }

  .block__item__about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    padding-left: 20%;
  }

  .hr__about {
    border: none;
    width: 70%;
    background-color: #edcd1f;

    height: 5px;
  }

  .our__projects {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .our__projects__items {
    background-color: #bdbaba;
    width: 25%;
  }

  .our__projects__text {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  .our__projects__photo {
    text-align: center;
    padding-top: 25px;
  }

  .wrapper__out {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  .block__row__out {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .block__column__out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 40px;
    max-height: 600px;
    width: 65%;
    height: 100%;
  }

  .block__item__out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    padding-left: 25px;
  }
  .text__out {
    width: 90%;
  }

  .hr__out {
    width: 80%;
    border: none;
    background-color: #edcd1f;

    height: 5px;
  }

  .f__product__topic {
    position: absolute;
    margin-top: 8%;
    margin-left: 23%;
    font-size: 17px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px;
    background-color: white;
  }

  .wrapper__exc {
    max-width: 100%;
    width: 100%;
    max-height: 1120px;
    height: 100%;
  }

  .block__row__topic__exc {
    display: flex;
    align-items: center;
    height: 60px;
    padding-top: 50px;
  }

  .block__column__topic__exc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    width: 100%;
    height: 100%;
  }

  .hr__topic__exc {
    border: none;
    background-color: #edcd1f;
    height: 5px;
    width: 50%;
    margin-left: 25%;
  }

  .block__row__exc {
    padding-top: 50px;
    display: flex;
    justify-content: space-around;
    min-height: 1000px;
  }

  .block__column__exc {
    background-color: rgba(255, 255, 255, 83%);
    max-height: 675px;
    width: 30%;
  }

  .block__item__exc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    height: 100%;
  }

  .h1__exc {
    padding: 5px 0px;
  }

  .hr__exc {
    border: none;
    background-color: #edcd1f;

    height: 5px;
    margin-left: 33%;
    margin-right: 33%;
  }

  .excellence__second__product {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }

  .p__excellence__sec__product {
    margin: 20px;
  }

  .projects {
    width: 100%;
  }

  .block__row__topic__projects {
    display: flex;
    align-items: center;
    max-height: 50%;
    height: 60px;
    padding-top: 50px;
  }

  .block__column__topic__projects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    width: 100%;
    height: 100%;
  }

  .block__item__topic__projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
  }

  .block__row__projects {
    display: flex;
    justify-content: space-evenly;
    max-height: 50%;
    height: 60px;
    padding-top: 50px;
  }

  .block__column__projects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-height: 600px;
    width: 13%;
    height: 100%;
  }

  .backGround {
    position: relative;
    top: 207px;
    left: 50px;
    width: 100%;
    height: 80px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.775);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .backgraundBig {
    width: 95%;
    height: 290px;
    background-color: #afafaf6e;
    z-index: -1;
    position: relative;
    top: 10px;
    left: 3%;
  }

  .hr__projects {
    border: none;
    background-color: #edcd1f;

    height: 5px;
    margin: 0px 25px;
  }

  .doki {
    width: 100%;
    /* max-width: 100%;
  height: 600px; */
  }

  .wrapper__doki {
    max-width: 100%;
    max-height: 600px;
    width: 100%;
    height: 600px;
  }

  .block__row__topic__doki {
    display: flex;
    align-items: center;
    max-height: 50%;
    height: 60px;
    padding-top: 50px;
  }

  .block__column__topic__doki {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    width: 100%;
    height: 100%;
  }

  .block__item__topic__doki {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
  }

  .block__row__doki {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    padding-top: 50px;
  }

  .block__column__doki {
    display: flex;
    flex-direction: row;
    max-height: 600px;
    width: 11%;
    height: 300px;
  }

  .block__item__doki {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
  }

  .backGround__doki {
    position: relative;
    top: 25px;
    width: 100%;
    height: 240px;
    z-index: -1;
    background-color: rgba(255, 255, 255, 83%);
    padding-top: 5px;
  }

  .hr__doki {
    border: none;
    background-color: #edcd1f;

    height: 5px;
    margin: 0px 70px;
  }

  .wrapper__contacts {
    position: relative;
    padding-top: 25px;
  }

  .block__row__contacts__topic {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 50%;
    height: 60px;
    padding-top: 50px;
  }

  .block__item__contacts__topic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
  }

  .block__row__contacts {
    display: flex;
    align-items: center;
    max-height: 50%;
    height: 160px;
    padding-top: 50px;
  }
  .block__column__contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    width: 100%;
    height: 100%;
  }

  .block__item__contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
  }

  .hr__contacts {
    border: none;
    background-color: #edcd1f;

    height: 3.5px;
    margin: 0px 25px;
  }

  .scroll__container {
    height: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .scroll__column {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
    width: 100%;
    overflow-x: scroll;
  }

  .scroll__column > .scroll__item > .p__ind {
    margin-left: 20px;
  }

  .scroll__item {
    min-width: 250px;
    min-height: 250px;
    width: 100%;
    height: 100%;

    margin-left: 70px;
    border-radius: 10px;
    border: 1px solid rgb(152, 152, 152);
    background-color: rgba(255, 255, 255, 83%);
  }

  .scroll__container__projects {
    height: 500px;
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media screen and (min-width: 1681px) {
  p {
    font-size: 20px;
  }

  .p__projects {
    font-size: 17px;
  }

  nav {
    width: 100%;
    height: 60px;
    background-color: #edcd1f;
    z-index: 1;
  }
  .nav_comp {
    width: inherit;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    width: inherit;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    background-color: #edcd1ede;
    box-shadow: 0px 8px 16px 0px rgb(237 205 30);
    padding: 20px 15px;
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .drop_text {
    font-size: 15px;
  }

  .navbar {
    position: absolute;
  }
  .nav_components {
    display: flex;
    justify-content: flex-end;
  }
  .hr-shelf {
    margin: -30px auto 10px;
    padding: 0;
    height: 50px;
    border: none;
    border-bottom: 1px solid #1f1209;
    box-shadow: 0 20px 20px -20px #333;
    width: 95%;
  }

  .nav_item {
    padding-top: 2vh;
    width: 30%;
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  .p__nav {
    padding-top: 1vmax;
    font-size: 30px;
  }

  .p__ind {
    padding-top: 20px;
    padding-left: 20px;
  }

  .p__out {
    padding-top: 40px;
  }

  .p__dok {
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    position: absolute;
    top: 65%;
  }

  h1 {
    text-align: center;
  }

  .h1__main {
    word-spacing: 23px;
    line-height: 50px;
  }

  .topic {
    height: 70px;
    width: 425px;
    padding-top: 25px;
    text-align: center;
    background-color: rgba(255, 255, 255, 83%);
  }
  .title__product {
    position: absolute;
    margin-top: 7%;
    margin-left: 24%;
    font-size: 19px;
    z-index: 1;
    width: 50%;
    text-align: center;
    box-shadow: 0px 8px 16px 0px;
    background-color: white;
  }

  .wrapper__about {
    max-width: 100%;
    max-height: 600px;
    width: 100%;
    height: 600px;
  }

  .block__row__about {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .block__column__about {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .block__column {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
  }

  .block__item__about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    padding-left: 20%;
  }

  .hr__about {
    border: none;
    width: 70%;
    background-color: #edcd1f;

    height: 5px;
  }

  .our__projects {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .our__projects__items {
    width: 25%;
    height: 110%;
    background-color: #bdbaba;
  }

  .our__projects__text {
    text-align: center;
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  .our__projects__photo {
    text-align: center;
    padding-top: 25px;
  }

  .wrapper__out {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  .block__row__out {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .block__column__out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 40px;
    max-height: 600px;
    width: 65%;
    height: 100%;
  }

  .block__item__out {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    align-items: center;
    padding-left: 25px;
  }
  .text__out {
    width: 90%;
  }

  .hr__out {
    width: 80%;
    border: none;
    background-color: #edcd1f;

    height: 5px;
  }

  .f__product__topic {
    position: absolute;
    margin-top: 9%;
    margin-left: 23%;
    font-size: 19px;
    z-index: 1;
    box-shadow: 0px 8px 16px 0px;
    background-color: white;
  }

  .wrapper__exc {
    max-width: 100%;
    width: 100%;
    max-height: 1120px;
    height: 100%;
  }

  .block__row__topic__exc {
    display: flex;
    align-items: center;
    height: 60px;
    padding-top: 50px;
  }

  .block__column__topic__exc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    width: 100%;
    height: 100%;
  }

  .hr__topic__exc {
    border: none;
    background-color: #edcd1f;
    height: 5px;
    width: 50%;
    margin-left: 25%;
  }

  .block__row__exc {
    padding-top: 50px;
    display: flex;
    justify-content: space-around;
    min-height: 1000px;
  }

  .block__column__exc {
    background-color: rgba(255, 255, 255, 83%);
    max-height: 675px;
    width: 30%;
  }

  .block__item__exc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
    height: 100%;
  }

  .h1__exc {
    padding: 5px 0px;
  }

  .hr__exc {
    border: none;
    background-color: #edcd1f;

    height: 5px;
    margin-left: 33%;
    margin-right: 33%;
  }

  .excellence__second__product {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }

  .p__excellence__sec__product {
    margin: 20px;
  }

  .projects {
    width: 100%;
  }

  .block__row__topic__projects {
    display: flex;
    align-items: center;
    max-height: 50%;
    height: 60px;
    padding-top: 190px;
  }

  .block__column__topic__projects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    width: 100%;
    height: 100%;
  }

  .block__item__topic__projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
  }

  .block__row__projects {
    display: flex;
    justify-content: space-evenly;
    max-height: 50%;
    height: 60px;
    padding-top: 50px;
  }

  .block__column__projects {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-height: 600px;
    width: 13%;
    height: 100%;
  }

  .backGround {
    position: relative;
    top: 207px;
    left: 50px;
    width: 100%;
    height: 80px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.775);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .backgraundBig {
    width: 95%;
    height: 290px;
    background-color: #afafaf6e;
    z-index: -1;
    position: relative;
    top: 10px;
    left: 3%;
  }

  .hr__projects {
    border: none;
    background-color: #edcd1f;

    height: 5px;
    margin: 0px 25px;
  }

  .doki {
    width: 100%;
    padding-top: 50px;
    /* max-width: 100%;
  height: 600px; */
  }

  .wrapper__doki {
    max-width: 100%;
    max-height: 600px;
    width: 100%;
    height: 600px;
  }

  .block__row__topic__doki {
    display: flex;
    align-items: center;
    max-height: 50%;
    height: 60px;
    padding-top: 50px;
  }

  .block__column__topic__doki {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    width: 100%;
    height: 100%;
  }

  .block__item__topic__doki {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
  }

  .block__row__doki {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    padding-top: 50px;
  }

  .block__column__doki {
    display: flex;
    flex-direction: row;
    max-height: 600px;
    width: 11%;
    height: 300px;
  }

  .block__item__doki {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
  }

  .backGround__doki {
    position: relative;
    top: 25px;
    width: 100%;
    height: 240px;
    z-index: -1;
    background-color: rgba(255, 255, 255, 83%);
    padding-top: 5px;
  }

  .hr__doki {
    border: none;
    background-color: #edcd1f;

    height: 5px;
    margin: 0px 70px;
  }

  .wrapper__contacts {
    position: relative;
    padding-top: 25px;
  }

  .block__row__contacts__topic {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 50%;
    height: 60px;
    padding-top: 50px;
  }

  .block__item__contacts__topic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
  }

  .block__row__contacts {
    display: flex;
    align-items: center;
    max-height: 50%;
    height: 160px;
    padding-top: 50px;
  }
  .block__column__contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-height: 600px;
    width: 100%;
    height: 100%;
  }

  .block__item__contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
  }

  .hr__contacts {
    border: none;
    background-color: #edcd1f;

    height: 3.5px;
    margin: 0px 25px;
  }

  .scroll__container {
    height: 600px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .scroll__column {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0px;
    width: 100%;
    overflow-x: scroll;
  }

  .scroll__column > .scroll__item > .p__ind {
    margin-left: 20px;
  }

  .scroll__item {
    min-width: 250px;
    min-height: 250px;
    width: 100%;
    height: 100%;

    margin-left: 70px;
    border-radius: 10px;
    border: 1px solid rgb(152, 152, 152);
    background-color: rgba(255, 255, 255, 83%);
  }

  .scroll__container__projects {
    height: 500px;
    max-width: 100%;
    overflow-x: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
}

/* @media screen and (min-width: 900px) {
  /* .block__column {
    max-width: 100%;
    max-height: 100%;
  } 
  .about {
    margin: 20px;
  }
  h1 {
    padding: 0px 10px;
  }
  .content {
    position: relative;
    top: 13%;
  }
  .main {
    height: 500px;
  }
  .backgraund {
    width: 100%;
  }
} */

/* @media screen and (max-width: 900px) {
  p {
    padding: 0px;
    font-size: 13px;
  }

  nav {
    height: 45px;
  }
  .p__nav {
    font-size: 22px;
  }

  .p__out {
    padding-top: 20px;
  }

  .padding__br {
    padding-bottom: 15px;
  }

  h1 {
    font-size: 14px;
  }
  .h1__main {
    word-spacing: 5px;
    line-height: 25px;
    padding: 23px 0px;
  }
  .main {
    height: 350px;
  }
  video {
    height: 350px;
  }
  .content {
    width: 75%;
    height: 25%;
  }

  .block__row__about {
    justify-content: flex-start;
    flex-direction: column;
  }

  .block__item__about {
    padding-left: 5%;
    padding-top: 15px;
  }

  .wrapper__out {
    max-height: 1000px;
  }

  .block__row__out {
    justify-content: flex-start;
    flex-direction: column-reverse;
  }

  .block__item__out {
    padding-left: 5%;
    padding-top: 10px;
  }

  .text__out {
    width: 93%;
  }

  .wrapper__exc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .block__row__topic__exc {
    max-width: 50%;
  }

  .block__item__topic__exc {
    width: 100%;
    height: 100%;
  }

  .topic {
    height: 7%;
    width: 40%;
    padding-top: 15px;
    border-radius: 10px;
  }

  .scroll {
    max-width: 100%;
    min-height: 100%;
    overflow-x: scroll;
  }

  .scroll__container {
    justify-content: center;
    height: 450px;
  }

  .p__ind {
    font-size: 10px;
    padding-top: 0px;
  }

  .projects {
    height: 810px;
  }
  .hr__projects {
    width: 10%;
  }
  .block__row__topic__projects {
    padding-top: 10%;
  }
  .block__item__topic__projects {
    width: 100%;
    align-items: center;
  }
  .block__row__projects {
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 0px;
  }
  .block__column__projects {
    min-width: 45%;
    min-height: 220px;
    justify-content: space-around;
  }
  .backGround {
    top: 25px;
    left: -30px;
    width: 60%;
    height: 30px;
  }

  .doki {
    height: 700px;
  }

  .p__dok {
    top: 67%;
    font-size: 12px;
  }

  .block__row__topic__doki {
    padding-top: 10px;
  }
  .block__item__topic__doki {
    width: 100%;
    height: 100%;
    padding-top: 10%;
    align-items: center;
  }
  .topic__doki {
    background-color: rgba(255, 255, 255, 83%);
    height: 50%;
    width: 50%;
    text-align: center;
  }
  .hr__doki {
    width: 80%;
    margin-left: 10%;
  }
  .block__row__doki {
    justify-content: space-between;
    padding-top: 20px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .block__column__doki {
    min-width: 30%;
    min-height: 120px;
    padding-top: 10px;
    height: 180px;
  }
  .block__item__doki {
    height: 10px;
    width: 30%;
  }
  .backGround__doki {
    padding-top: 7px;
    top: 10px;
    height: 170px;
  }
  .block__row__contacts__topic {
    padding-top: 10px;
  }
  .block__item__contacts__topic {
    width: 30%;
  }
  .block__row__contacts {
    padding-top: 30px;
  }
  .p__contacts {
    font-size: 15px;
  }

  .wrapper__contacts {
    border-radius: 10px;
    border: 1px solid rgb(152, 152, 152);
    background-color: rgba(230, 230, 230, 83%);
    height: auto;
  }
} */
